import React,{Component} from 'react';

class HobiesContent extends Component{
    render(){
        return(
            <div className="hobies">
                <ul>
                    <li>Programmation</li>
                    <li>Mathematiques</li>
                    <li>Basketball</li>
                    <li>Mythologie</li>
                    <li>Nature</li>
                </ul>
            </div>
        );
    }
}

export default HobiesContent;